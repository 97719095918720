import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Container, TextField, Button, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../styles/ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        "An email with instructions to reset your password has been sent!",
      );
      setError("");
    } catch (err) {
      setError(err.message);
      setMessage("");
    }
  };

  const handleGoBackToLogin = () => {
    navigate("/top-secret");
  };

  return (
    <Container className="forgot-password-container">
      <Paper className="forgot-password-paper">
        <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
          Forgot Password
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enter your email below, and we will send you instructions to reset
          your password.
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        {error && (
          <Typography color="error" className="error">
            {error}
          </Typography>
        )}
        {message && (
          <>
            <Typography color="success" className="success">
              {message}
            </Typography>
            <Button
              onClick={handleGoBackToLogin}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: "20px" }}
            >
              Go Back to Login
            </Button>
          </>
        )}
        <Button
          onClick={handlePasswordReset}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: "20px" }}
        >
          Reset Password
        </Button>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = ({ element, ...rest }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  return currentUser ? (
    element
  ) : (
    <Navigate to="/under-construction" state={{ from: location }} replace />
  );
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { guests } from "../constants";
import "../styles/AdminDashboard.css";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
);

const AdminDashboard = () => {
  const [rsvps, setRsvps] = useState([]);
  const [selectedRsvp, setSelectedRsvp] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRsvps, setFilteredRsvps] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const db = getFirestore();

  useEffect(() => {
    const fetchRsvps = async () => {
      const rsvpCollection = collection(db, "rsvps");
      const rsvpSnapshot = await getDocs(rsvpCollection);
      const rsvpList = rsvpSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRsvps(rsvpList);
      setFilteredRsvps(rsvpList);
    };

    fetchRsvps();
  }, [db]);

  const handleRowClick = (rsvp) => {
    setSelectedRsvp(rsvp);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRsvp(null);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === "") {
      setFilteredRsvps(rsvps);
    } else {
      const filtered = rsvps.filter((rsvp) =>
        rsvp.name.toLowerCase().includes(term.toLowerCase()),
      );
      setFilteredRsvps(filtered);
    }
  };

  // Filter helper function to remove irrelevant entries
  const isValidEntry = (value) =>
    value &&
    !["nope", "no", "n/a", "none", "na", "n.a.", "", " "].includes(
      value.trim().toLowerCase(),
    );

  // Analytics Calculations
  const totalInvites = guests.length;
  const totalResponses = rsvps.length;
  const yesCount = rsvps.filter((rsvp) => rsvp.attending === "yes").length;
  const noCount = rsvps.filter((rsvp) => rsvp.attending === "no").length;
  const pendingCount = totalInvites - totalResponses;
  const yesPercentage = totalResponses
    ? ((yesCount / totalResponses) * 100).toFixed(2)
    : 0;
  const noPercentage = totalResponses
    ? ((noCount / totalResponses) * 100).toFixed(2)
    : 0;

  // Guests with Severe Allergies
  const guestsWithAllergies = rsvps.filter((rsvp) =>
    isValidEntry(rsvp.severeAllergies),
  );

  // Guests with Comments or Special Requests
  const guestsWithComments = rsvps.filter((rsvp) =>
    isValidEntry(rsvp.comments),
  );

  const pieData = {
    labels: ["Yes", "No", "Pending"],
    datasets: [
      {
        data: [yesCount, noCount, pendingCount],
        backgroundColor: ["#4CAF50", "#F44336", "#FFC107"], // Green, Red, Amber
      },
    ],
  };

  const barData = {
    labels: ["Yes", "No", "Pending"],
    datasets: [
      {
        label: "RSVP Status",
        data: [yesCount, noCount, pendingCount],
        backgroundColor: ["#4CAF50", "#F44336", "#FFC107"],
      },
    ],
  };

  const exportToExcel = () => {
    // Prepare data for export
    const dataForExport = rsvps.map((rsvp) => {
      console.log("RSVP: ", rsvp);
      const plusOneName =
        rsvp.plusOneData && rsvp.plusOneData.name
          ? rsvp.plusOneData.name
          : "N/A";
      console.log("Plus One Name: ", plusOneName);
      const plusOneEmail =
        rsvp.plusOneData && rsvp.plusOneData.email
          ? rsvp.plusOneData.email
          : "N/A";
      console.log("Plus One Email: ", plusOneEmail);
      const plusOneAttending =
        rsvp.plusOneData && rsvp.plusOneData === "accept" ? "Yes" : "No";
      console.log("Plus One Attending: ", plusOneAttending);

      return {
        "Guest Name": rsvp.name,
        "Guest Email": rsvp.email,
        "Guest Phone": rsvp.phoneNumber,
        Attending: rsvp.attending === "yes" ? "Yes" : "No",
        "Severe Allergies": isValidEntry(rsvp.severeAllergies)
          ? rsvp.severeAllergies
          : "None",
        Comments: isValidEntry(rsvp.comments) ? rsvp.comments : "None",
        "Plus One Name": plusOneName,
        "Plus One Email": plusOneEmail,
        "Plus One Attending": plusOneAttending,
      };
    });

    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "RSVPs");

    // Format the worksheet as a table
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    worksheet["!autofilter"] = { ref: XLSX.utils.encode_range(range) };

    // Write the workbook to a file
    XLSX.writeFile(workbook, "RSVPs.xlsx");
  };

  const sendMassEmail = () => {
    const emails = rsvps.map((rsvp) => rsvp.email).join(",");
    window.location.href = `mailto:${emails}?subject=RSVP%20Update`;
  };

  const getRsvpStatus = (name) => {
    const rsvp = rsvps.find((rsvp) => rsvp.name === name);
    if (rsvp) {
      return rsvp.attending === "yes" ? "Yes" : "No";
    }
    // Check if the person is a plus one
    const primaryRsvp = rsvps.find(
      (rsvp) => rsvp.plusOneData && rsvp.plusOneData.name === name,
    );
    return primaryRsvp && primaryRsvp.attending === "yes" ? "Yes" : "Pending";
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedGuests = React.useMemo(() => {
    let sortableGuests = [...guests];
    if (sortConfig.key !== null) {
      sortableGuests.sort((a, b) => {
        if (sortConfig.key === "rsvpStatus") {
          const statusOrder = { Pending: 0, No: 1, Yes: 2 };
          return sortConfig.direction === "ascending"
            ? statusOrder[getRsvpStatus(a.name)] -
                statusOrder[getRsvpStatus(b.name)]
            : statusOrder[getRsvpStatus(b.name)] -
                statusOrder[getRsvpStatus(a.name)];
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableGuests;
  }, [guests, sortConfig]);

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <ArrowUpward />
      ) : (
        <ArrowDownward />
      );
    }
    return null;
  };

  return (
    <Container className="admin-dashboard">
      <Typography variant="h2" gutterBottom className="dashboard-title">
        Admin Dashboard
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper className="chart-container">
            <Typography variant="h6" gutterBottom>
              RSVP Summary
            </Typography>
            <Typography variant="body1" gutterBottom>
              Total Invites: {totalInvites}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Total Responses: {totalResponses}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Yes: {yesCount} | No: {noCount} | Pending: {pendingCount}
            </Typography>
          </Paper>
          <Paper className="chart-container">
            <Typography variant="h6" gutterBottom>
              Attendance Pie Chart
            </Typography>
            <Pie data={pieData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="chart-container">
            <Typography variant="h6" gutterBottom>
              Attendance Bar Chart
            </Typography>
            <Bar data={barData} />
          </Paper>
          <Paper className="chart-container">
            <Typography variant="h6" gutterBottom>
              Percentage Breakdown
            </Typography>
            <Typography variant="body1" gutterBottom>
              Yes: {yesPercentage}% | No: {noPercentage}% | Pending:{" "}
              {(100 - yesPercentage - noPercentage).toFixed(2)}%
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Guests with Allergies */}
      {guestsWithAllergies.length > 0 && (
        <Paper className="info-section">
          <Typography variant="h4" gutterBottom>
            Guests with Severe Allergies or Dietary Restrictions
          </Typography>
          <List>
            {guestsWithAllergies.map((guest) => (
              <ListItem key={guest.id}>
                <ListItemText
                  primary={guest.name}
                  secondary={guest.severeAllergies}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* Guests with Comments */}
      {guestsWithComments.length > 0 && (
        <Paper className="info-section">
          <Typography variant="h4" gutterBottom>
            Guests with Comments or Special Requests
          </Typography>
          <List>
            {guestsWithComments.map((guest) => (
              <ListItem key={guest.id}>
                <ListItemText primary={guest.name} secondary={guest.comments} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* RSVP List */}
      <Paper className="rsvp-list">
        <Typography variant="h4" gutterBottom>
          RSVP List
        </Typography>
        <TextField
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton onClick={() => setSearchTerm("")}>
                    <Close />
                  </IconButton>
                ) : (
                  <Search />
                )}
              </InputAdornment>
            ),
          }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Attending</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Plus One</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRsvps.map((rsvp) => (
                <TableRow
                  key={rsvp.id}
                  hover
                  onClick={() => handleRowClick(rsvp)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{rsvp.name}</TableCell>
                  <TableCell>
                    {rsvp.attending === "yes" ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>{rsvp.email}</TableCell>
                  <TableCell>{rsvp.phoneNumber}</TableCell>
                  <TableCell>
                    {rsvp.plusOneData && rsvp.plusOneData.name
                      ? rsvp.plusOneData.name
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button className="custom-button" onClick={exportToExcel}>
          Export to Excel
        </Button>
        <Button className="custom-button" onClick={sendMassEmail}>
          Send Mass Email
        </Button>
      </Paper>
      {/* Guest List */}
      <Paper className="guest-list">
        <Typography variant="h4" gutterBottom>
          Guest List
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("name")}>
                  Name {renderSortArrow("name")}
                </TableCell>
                <TableCell onClick={() => handleSort("plusOne")}>
                  Plus One Allowed {renderSortArrow("plusOne")}
                </TableCell>
                <TableCell onClick={() => handleSort("rsvpStatus")}>
                  RSVP Status {renderSortArrow("rsvpStatus")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedGuests.map((guest, index) => (
                <TableRow key={index}>
                  <TableCell>{guest.name}</TableCell>
                  <TableCell>{guest.plusOne ? "Yes" : "No"}</TableCell>
                  <TableCell>{getRsvpStatus(guest.name)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* RSVP Details Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>RSVP Details</DialogTitle>
        {selectedRsvp && (
          <DialogContent>
            <Typography variant="h6">Guest Information</Typography>
            <Typography>Name: {selectedRsvp.name}</Typography>
            <Typography>Email: {selectedRsvp.email}</Typography>
            <Typography>Phone: {selectedRsvp.phoneNumber}</Typography>
            <Typography>
              Attending: {selectedRsvp.attending === "yes" ? "Yes" : "No"}
            </Typography>
            {selectedRsvp.attending === "yes" && (
              <>
                <Typography>
                  Severe Allergies:{" "}
                  {isValidEntry(selectedRsvp.severeAllergies)
                    ? selectedRsvp.severeAllergies
                    : "None"}
                </Typography>
                <Typography>
                  Comments:{" "}
                  {isValidEntry(selectedRsvp.comments)
                    ? selectedRsvp.comments
                    : "None"}
                </Typography>
              </>
            )}
            {selectedRsvp.plusOneData && selectedRsvp.plusOneData.name && (
              <>
                <Typography variant="h6" style={{ marginTop: "15px" }}>
                  Plus One Information
                </Typography>
                <Typography>Name: {selectedRsvp.plusOneData.name}</Typography>
                <Typography>Email: {selectedRsvp.plusOneData.email}</Typography>
                <Typography>
                  Attending:{" "}
                  {selectedRsvp.plusOneData.status === "accept" ? "Yes" : "No"}
                </Typography>
              </>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleDialogClose} className="custom-button">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminDashboard;

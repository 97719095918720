// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "../styles/HeroSection.css";

const HeroSection = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date("2025-08-16T15:00:00");
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function logFontFamily(element) {
    const computedStyle = window.getComputedStyle(element);
    console.log(
      `Font family for ${element.tagName}: ${computedStyle.fontFamily}`,
    );
  }

  useEffect(() => {
    const heroTitle = document.querySelector(".hero-container h1");
    logFontFamily(heroTitle);

    const countdownItems = document.querySelectorAll(".countdown-item .time");
    countdownItems.forEach((item) => logFontFamily(item));
  }, []);

  return (
    <div className="hero-page">
      <motion.div
        className="hero-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>Kelly & Benjamin</h1>
        <div className="content">
          <div className="event-details">
            <div className="event-info">
              <div className="event-date">
                <p>SATURDAY</p>
                <p>Aug 16th, 2025</p>
                <p>3:00 PM</p>
              </div>
              <div className="divider"></div>
              <div className="event-location">
                <p>St. Raymond de Peñafort Catholic Church</p>
                <p>Mount Prospect, IL</p>
              </div>
            </div>
          </div>
          {/* TODO: Update this once we open up the RSVP page */}
          {/* <Link to="/rsvp" className="rsvp-button">
            RSVP
          </Link> */}
          <div className="countdown">
            {Object.keys(timeLeft).length > 0 && (
              <>
                <div className="countdown-item">
                  <div className="time">{timeLeft.days}</div>
                  <div>Days</div>
                </div>
                <div className="colon">:</div>
                <div className="countdown-item">
                  <div className="time">{timeLeft.hours}</div>
                  <div>Hours</div>
                </div>
                <div className="colon">:</div>
                <div className="countdown-item">
                  <div className="time">{timeLeft.minutes}</div>
                  <div>Minutes</div>
                </div>
                <div className="colon">:</div>
                <div className="countdown-item">
                  <div className="time">{timeLeft.seconds}</div>
                  <div>Seconds</div>
                </div>
              </>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HeroSection;
